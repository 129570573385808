/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable linebreak-style */
/* eslint-disable import/extensions */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable-next-line no-unused-vars */
// eslint-disable-next-line react/jsx-wrap-multilines

import React, { Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import MetaTags from "MetaTags";
import LoadingSpinner from "parts/LoadingSpinner";
import { DiscussProjectPage } from "pages/DiscussProjectPage";
import { ProjectDetailPage } from "pages/ProjectDetailPage";
import ZikirmatikKVKK from "pages/documents/ZikirmatikKVKK";

import "./i18n";

import "./assets/css/styles.css";

const LandingPage = React.lazy(() => import("./pages/LandingPage"));
const ProjectPage = React.lazy(() => import("./pages/ProjectPage"));
const NotFoundPage = React.lazy(() => import("./pages/NotFoundPage"));
const TeamPage = React.lazy(() => import("./pages/TeamPage"));

function App() {
  return (
    <Routes>
      <Route
        exact
        path="/"
        element={
          <>
            <MetaTags page="home" />
            <Suspense fallback={<LoadingSpinner />}>
              <LandingPage />
            </Suspense>
          </>
        }
      />

      <Route
        path="/project"
        element={
          <>
            <MetaTags page="project" />
            <Suspense fallback={<LoadingSpinner />}>
              <ProjectPage />
            </Suspense>
          </>
        }
      />
      <Route
        exact
        path="/project/:id"
        element={
          <>
            <MetaTags page="project" />
            <Suspense fallback={<LoadingSpinner />}>
              <ProjectDetailPage />
            </Suspense>
          </>
        }
      />
      <Route
        exact
        path="/team"
        element={
          <>
            <MetaTags page="team" />
            <Suspense fallback={<LoadingSpinner />}>
              <TeamPage />
            </Suspense>
          </>
        }
      />
      <Route
        exact
        path="/discuss-project"
        element={
          <>
            <MetaTags page="discussProject" />
            <Suspense fallback={<LoadingSpinner />}>
              <DiscussProjectPage />
            </Suspense>
          </>
        }
      />
      <Route
        path="*"
        element={
          <>
            <MetaTags page="notFoundPage" />
            <NotFoundPage />
          </>
        }
      />
      <Route
        path="/zikirmatik-kvkk"
        element={
          <>
            <MetaTags page="zikirmatikKVKKDocument" />
            <ZikirmatikKVKK />
          </>
        }
      />
    </Routes>
  );
}

export default App;
