import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";

const MetaTags = ({ page }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const { id } = useParams();
  const meta = id
    ? t(`meta.${page}/${id}`, { returnObjects: true })
    : t(`meta.${page}`, { returnObjects: true });

  return (
    <>
      <title>{meta.title}</title>
      <meta name="description" content={meta.description} />
      <meta name="keywords" content={meta.keywords} />
      <link
        rel="alternate"
        hrefLang="x-default"
        href={`https://aimazingapps.com${location.pathname}`}
      />
      <link
        rel="alternate"
        hrefLang="en"
        href={`https://aimazingapps.com/en${location.pathname}`}
      />
      <link
        rel="alternate"
        hrefLang="tr"
        href={`https://aimazingapps.com/tr${location.pathname}`}
      />
    </>
  );
};

MetaTags.propTypes = { page: PropTypes.string.isRequired };

export default MetaTags;
