/* eslint-disable react/prop-types */
import React from "react";
import {
  GooglePlayButton,
  AppGalleryButton,
  AppStoreButton,
  ButtonsContainer,
} from "react-mobile-app-button";

export default function MobileAppButton({ link, isActive = false }) {
  // Butonların kaynak tanımları
  const appGallery = "appgallery";
  const playStore = "play.google";
  const appStore = "apps.apple";

  // Düğme verileri
  const buttonData = [
    { key: appGallery, component: AppGalleryButton },
    { key: playStore, component: GooglePlayButton },
    { key: appStore, component: AppStoreButton },
  ];

  // Kontroller
  if (!link || !isActive) {
    return null; // Eğer link veya isActive uygun değilse, hiçbir şey render edilmez
  }

  return (
    <ButtonsContainer>
      {buttonData.map(({ key, component: ButtonComponent }) =>
        link.includes(key) ? (
          <ButtonComponent
            key={key}
            url={link}
            theme="light"
            className="custom-style"
          />
        ) : null
      )}
    </ButtonsContainer>
  );
}
