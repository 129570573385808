/* eslint-disable import/extensions */

import i18n from "../i18n.js";

// Services Img Imports
import Web from "../assets/images/Services/Web.png";
import Mobile from "../assets/images/Services/Mobile.png";
import UIUX from "../assets/images/Services/Design.png";
import Ai from "../assets/images/Services/Ai.png";

// Portfolio Img Imports
import Recruiting from "../assets/images/Portfolio/Recruiting.png";
import Stream from "../assets/images/Portfolio/Stream.png";
import Freelance from "../assets/images/Portfolio/Freelance.png";
import Aura from "../assets/images/Portfolio/Aura.png";
import Surtido from "../assets/images/Portfolio/Surtido.png";
import ManagementApp from "../assets/images/Portfolio/ManagementApp.png";

// Advantages
import Communicative from "../assets/images/Advantages/Communicative.png";
import Collaborative from "../assets/images/Advantages/Collaborative.png";
import Management from "../assets/images/Advantages/Management.png";
import Favorite from "../assets/images/Advantages/Favorite.png";

// Testimonials
import Avatar1 from "../assets/images/Testimonials/avatar_1.png";
import Avatar2 from "../assets/images/Testimonials/avatar_2.png";
import Avatar3 from "../assets/images/Testimonials/avatar_3.png";
import Avatar4 from "../assets/images/Testimonials/avatar_4.png";

// TeamMembers
import CEO from "../assets/images/TeamMembers/CEO.jpg";
import HRD from "../assets/images/TeamMembers/HRD.jpg";
import Finance from "../assets/images/TeamMembers/Finance.png";
import ProjectManager from "../assets/images/TeamMembers/PM.png";
import Frontend1 from "../assets/images/TeamMembers/Frontend1.png";
import Frontend2 from "../assets/images/TeamMembers/Frontend2.png";
import Backend1 from "../assets/images/TeamMembers/Backend1.jpg";
import Backend2 from "../assets/images/TeamMembers/Backend2.png";
import Mobile1 from "../assets/images/TeamMembers/Mobile1.png";
import Mobile2 from "../assets/images/TeamMembers/Mobile2.png";
import Mobile3 from "../assets/images/TeamMembers/Mobile3.png";
import UIUX1 from "../assets/images/TeamMembers/UIUX1.png";
import UIUX2 from "../assets/images/TeamMembers/UIUX2.png";

//  Ana sayfa sundugumuz hizmetler

export const Services = () => [
  {
    title: i18n.t("services.web.name"),
    imageUrl: Web,
    animation: "left",
  },
  {
    title: i18n.t("services.mobileDevelopment.name"),
    imageUrl: Mobile,
    animation: "up",
  },
  {
    title: i18n.t("services.uiUx.name"),
    imageUrl: UIUX,
    animation: "right",
  },
  {
    title: i18n.t("services.ai.name"),
    imageUrl: Ai,
    animation: "left",
  },
];
//  Portfilo page projelerimiz
export const Portfolios = () => [
  {
    id: "asd1293uasdads1",
    title: i18n.t("portfolios.recruitingApp.title"),
    imageUrl: Recruiting,
    type: i18n.t("portfolios.recruitingApp.type"),
    responsibility: [
      i18n.t("portfolios.recruitingApp.responsibility.mobile.tecnologyTypes"),
      i18n.t("portfolios.recruitingApp.responsibility.uiUx.tecnologyTypes"),
    ],
    url: [
      i18n.t("portfolios.recruitingApp.appGalleryUrl"),
      i18n.t("portfolios.recruitingApp.playStoreUrl"),
      i18n.t("portfolios.recruitingApp.appStoreUrl"),
    ],
    description: i18n.t("portfolios.recruitingApp.description"),
    credit: "https://dribbble.com/shots/15164950-Recruiting-app",
  },
  {
    id: "asd1293uhjkhkjh2",
    title: i18n.t("portfolios.streamApp.title"),
    imageUrl: Stream,
    type: i18n.t("portfolios.streamApp.type"),
    responsibility: [
      i18n.t("portfolios.streamApp.responsibility.mobile.tecnologyTypes"),
      i18n.t("portfolios.streamApp.responsibility.uiUx.tecnologyTypes"),
    ],
    url: [
      i18n.t("portfolios.streamApp.appGalleryUrl"),
      i18n.t("portfolios.streamApp.playStoreUrl"),
      i18n.t("portfolios.streamApp.appStoreUrl"),
    ],
    description: i18n.t("portfolios.streamApp.description"),
    credit: "https://dribbble.com/shots/15276430-Stream",
  },
  {
    id: "asd1293uvbvcbbd3",
    title: i18n.t("portfolios.freelanceApp.title"),
    imageUrl: Freelance,
    type: i18n.t("portfolios.freelanceApp.type"),
    responsibility: [
      i18n.t("portfolios.freelanceApp.responsibility.mobile.tecnologyTypes"),
      i18n.t("portfolios.freelanceApp.responsibility.uiUx.tecnologyTypes"),
    ],
    url: [
      i18n.t("portfolios.freelanceApp.appGalleryUrl"),
      i18n.t("portfolios.freelanceApp.playStoreUrl"),
      i18n.t("portfolios.freelanceApp.appStoreUrl"),
    ],
    description: i18n.t("portfolios.freelanceApp.description"),
    credit: "https://dribbble.com/shots/15223131-Freelance-Mobile-App-Concept",
  },
  {
    id: "asd1293ufgdfgs4",
    title: i18n.t("portfolios.auraApp.title"),
    imageUrl: Aura,
    type: i18n.t("portfolios.auraApp.type"),
    responsibility: [
      i18n.t("portfolios.auraApp.responsibility.web.tecnologyTypes"),
      i18n.t("portfolios.auraApp.responsibility.uiUx.tecnologyTypes"),
    ],
    url: [
      i18n.t("portfolios.auraApp.appGalleryUrl"),
      i18n.t("portfolios.auraApp.playStoreUrl"),
      i18n.t("portfolios.auraApp.appStoreUrl"),
    ],
    description: i18n.t("portfolios.auraApp.description"),
    credit: "https://dribbble.com/shots/15176338-Aura-Website-Main-Page",
  },
  {
    id: "asd1293ulskmnb5",
    title: i18n.t("portfolios.surtidoRicoApp.title"),
    imageUrl: Surtido,
    type: i18n.t("portfolios.surtidoRicoApp.type"),
    responsibility: [
      i18n.t("portfolios.surtidoRicoApp.responsibility.web.tecnologyTypes"),
      i18n.t("portfolios.surtidoRicoApp.responsibility.uiUx.tecnologyTypes"),
    ],
    url: [
      i18n.t("portfolios.surtidoRicoApp.appGalleryUrl"),
      i18n.t("portfolios.surtidoRicoApp.playStoreUrl"),
      i18n.t("portfolios.surtidoRicoApp.appStoreUrl"),
    ],
    description: i18n.t("portfolios.surtidoRicoApp.description"),
    credit: "https://dribbble.com/shots/15173118-Surtido-Rico",
  },
  {
    id: "asd1293ulkmnbj6",
    title: i18n.t("portfolios.courcesManagementApp.title"),
    imageUrl: ManagementApp,
    type: i18n.t("portfolios.courcesManagementApp.type"),
    responsibility: [
      i18n.t(
        "portfolios.courcesManagementApp.responsibility.web.tecnologyTypes"
      ),
      i18n.t(
        "portfolios.courcesManagementApp.responsibility.uiUx.tecnologyTypes"
      ),
    ],
    url: [
      i18n.t("portfolios.courcesManagementApp.appGalleryUrl"),
      i18n.t("portfolios.courcesManagementApp.playStoreUrl"),
      i18n.t("portfolios.courcesManagementApp.appStoreUrl"),
    ],
    description: i18n.t("portfolios.courcesManagementApp.description"),
    credit:
      "https://dribbble.com/shots/15197890-Courses-Management-and-Promoting-Web-App",
  },
  {
    id: "asd1293ulkmnbasd6",
    title: i18n.t("portfolios.courcesManagementAiApp.title"),
    imageUrl: ManagementApp,
    type: i18n.t("portfolios.courcesManagementAiApp.type"),
    responsibility: [
      i18n.t(
        "portfolios.courcesManagementAiApp.responsibility.web.tecnologyTypes"
      ),
      i18n.t(
        "portfolios.courcesManagementAiApp.responsibility.ai.tecnologyTypes"
      ),
    ],
    url: [
      i18n.t("portfolios.courcesManagementApp.appGalleryUrl"),
      i18n.t("portfolios.courcesManagementApp.playStoreUrl"),
      i18n.t("portfolios.courcesManagementApp.appStoreUrl"),
    ],
    description: i18n.t("portfolios.courcesManagementAiApp.description"),
    credit:
      "https://dribbble.com/shots/15197890-Courses-Management-and-Promoting-Web-App",
  },
];
//  Ana sayfa sirket vizyonumuz
export const Advantages = () => [
  [
    {
      title: i18n.t("advantages.communicative.title"),
      description: i18n.t("advantages.communicative.description"),
      imageUrl: Communicative,
    },
    {
      title: i18n.t("advantages.management.title"),
      description: i18n.t("advantages.management.description"),
      imageUrl: Management,
    },
  ],
  [
    {
      title: i18n.t("advantages.collaborative.title"),
      description: i18n.t("advantages.collaborative.description"),
      imageUrl: Collaborative,
    },
    {
      title: i18n.t("advantages.favorite.title"),
      description: i18n.t("advantages.favorite.description"),
      imageUrl: Favorite,
    },
  ],
];
//  Ana sayfa Proje yaptiran kisiler
export const Testimonials = () => [
  {
    id: 1,
    name: i18n.t("testimonials.custumer1.name"),
    company: i18n.t("testimonials.custumer1.company"),
    testimoni: i18n.t("testimonials.custumer1.testimoni"),
    imageUrl: Avatar1,
  },
  {
    id: 2,
    name: i18n.t("testimonials.custumer2.name"),
    company: i18n.t("testimonials.custumer2.company"),
    testimoni: i18n.t("testimonials.custumer2.testimoni"),
    imageUrl: Avatar2,
  },
  {
    id: 3,
    name: i18n.t("testimonials.custumer3.name"),
    company: i18n.t("testimonials.custumer3.company"),
    testimoni: i18n.t("testimonials.custumer3.testimoni"),
    imageUrl: Avatar3,
  },
  {
    id: 4,
    name: i18n.t("testimonials.custumer4.name"),
    company: i18n.t("testimonials.custumer4.company"),
    testimoni: i18n.t("testimonials.custumer4.testimoni"),
    imageUrl: Avatar4,
  },
];

export const TeamMembers = [
  {
    name: "Enes Pecenek",
    position: "CEO",
    imageUrl: CEO,
  },
  {
    name: "Eda Gökpınar",
    position: "HRD",
    imageUrl: HRD,
  },
  {
    name: "Melisa Atak",
    position: "Finance",
    imageUrl: Finance,
  },
  {
    name: "İbrahim Akpınar",
    position: "Project Manager",
    imageUrl: ProjectManager,
  },
  {
    name: "Okan Tuna",
    position: "Front-end Developer",
    imageUrl: Frontend1,
  },
  {
    name: "Nuri Ceylan",
    position: "Front-end Developer",
    imageUrl: Frontend2,
  },
  {
    name: "Selim Ünlü",
    position: "Back-end Developer",
    imageUrl: Backend1,
  },
  {
    name: "Engin Yurttaş",
    position: "Back-end Developer",
    imageUrl: Backend2,
  },
  {
    name: "Burak Karadere",
    position: "Mobile Developer",
    imageUrl: Mobile1,
  },
  {
    name: "Ferhat Yaylalı",
    position: "Mobile Developer",
    imageUrl: Mobile2,
  },
  {
    name: "Derin Öncü",
    position: "Mobile Developer",
    imageUrl: Mobile3,
  },
  {
    name: "Ahmet Turan",
    position: "UI/UX Designer",
    imageUrl: UIUX2,
  },
  {
    name: "Ayşe Deniz",
    position: "UI/UX Designer",
    imageUrl: UIUX1,
  },
];

export const NotFoundPageResources = () => ({
  PAGE_DESCRIPTION: i18n.t("notFoundPage.pageDescription"),
  PAGE_ACTION: i18n.t("notFoundPage.pageAction"),
});

export const AdvantagePartsResources = () => ({
  TITLE: i18n.t("advantageParts.title"),
  DESCRIPTION: i18n.t("advantageParts.description"),
});

export const AllPortfolioPartsResources = () => ({
  MOBILE: i18n.t("allPortfolioParts.mobile"),
  WEBSITE: i18n.t("allPortfolioParts.website"),
  AI: i18n.t("allPortfolioParts.ai"),
  UIUX: i18n.t("allPortfolioParts.ui_ux"),
});

export const DiscussPartsResources = () => ({
  TITLE: i18n.t("discussParts.title"),
  ACTION_BUTTON: i18n.t("discussParts.actionButton"),
  DESCRIPTION: i18n.t("discussParts.description"),
});

export const DiscussFormPartsResources = () => ({
  TITLE: i18n.t("discussFormParts.title"),
  DESCRIPTION: i18n.t("discussFormParts.description"),
  NAME_PLACE_HOLDER: i18n.t("discussFormParts.namePlaceHolder"),
  COMPANY_PLACE_HOLDER: i18n.t("discussFormParts.companyPlaceHolder"),
  EMAIL_PLACE_HOLDER: i18n.t("discussFormParts.emailPlaceHolder"),
  PHONE_PLACE_HOLDER: i18n.t("discussFormParts.phonePlaceHolder"),
  PROJECT_PLACE_HOLDER: i18n.t("discussFormParts.projectPlaceHolder"),
  SUBMIT_BUTTON: i18n.t("discussFormParts.submitButton"),
});

export const FooterPartsResources = () => ({
  CONTACT_DESCRIPTION: i18n.t("footerParts.contactDescription"),
  CONTACT_SUBDESCRIPTION: i18n.t("footerParts.contactSubDescription"),
  CONTACT_OFFICE: i18n.t("footerParts.contactOffice"),
  CONTACT_SOCIAL: i18n.t("footerParts.contactSocial"),
  COPYRIGHT: i18n.t("footerParts.copyright"),
});

export const HeaderPartsResources = () => ({
  HOME_HEADER: i18n.t("headerParts.home.header"),
  TEAM_HEADER: i18n.t("headerParts.team.header"),
  PROJECT_HEADER: i18n.t("headerParts.project.header"),
  DISCUSS_PROJECT_HEADER: i18n.t("headerParts.discussProject.header"),
});

export const HeroPartsResources = () => ({
  HEADER: i18n.t("heroParts.header"),
  SUBHEADER: i18n.t("heroParts.subHeader"),
  DESCRIPTION: i18n.t("heroParts.description"),
  MORE_BUTTON: i18n.t("heroParts.moreButton"),
  MORE_LINK: i18n.t("heroParts.moreLink"),
});

export const HeroPortfolioPartsResources = () => ({
  HEADER: i18n.t("heroPortfolioParts.header"),
  DESCRIPTION: i18n.t("heroPortfolioParts.description"),
});

export const HeroTeamPartsResources = () => ({
  HEADER: i18n.t("heroTeamParts.header"),
  DESCRIPTION: i18n.t("heroTeamParts.description"),
});

export const PortfolioPartsResources = () => ({
  HEADER: i18n.t("portfolioParts.header"),
  DESCRIPTION: i18n.t("portfolioParts.description"),
  MORE_BUTTON: i18n.t("portfolioParts.moreButton"),
});

export const PortfolioDetailPartsResources = () => ({
  HEADER: i18n.t("portfolioDetailParts.header"),
  BACK_BUTTON: i18n.t("portfolioDetailParts.backButton"),
  MORE_BUTTON: i18n.t("portfolioDetailParts.moreButton"),
  HEADER_DETAIL: i18n.t("portfolioDetailParts.headerDetail"),
  TOOLS_DESCRIPTION: i18n.t("portfolioDetailParts.toolsDescription"),
  HEADER_RESPONSIBILITIES: i18n.t(
    "portfolioDetailParts.headerResponsibilities"
  ),
  ORJINAL_PROJECT: i18n.t("portfolioDetailParts.orjinalProject"),
});

export const ServicePartsResources = () => ({
  HEADER: i18n.t("serviceParts.header"),
  DESCRIPTION: i18n.t("serviceParts.description"),
});

export const TestimonialsPartsResources = () => ({
  HEADER: i18n.t("testimonialsParts.header"),
  DESCRIPTION: i18n.t("testimonialsParts.description"),
});

export const AllPortfolioTypesResources = () => ({
  MOBILE_APPS: i18n.t("allPortfolioTypes.mobileApps"),
  WEBSITE: i18n.t("allPortfolioTypes.website"),
  AI_PROJECT: i18n.t("allPortfolioTypes.aiProject"),
});
