/* eslint-disable-next-line no-unused-vars */
/* eslint-disable-next-line react/jsx-wrap-multilines */

import React, { useState } from "react";

import GridLoader from "react-spinners/GridLoader.js";

export default function LoadingSpinner() {
  const [load, setLoad] = useState(true);
  setTimeout(() => {
    setLoad(false);
  }, 6000);

  return (
    <div className="flex flex-col w-full h-screen justify-center bg-white">
      <div className="flex w-full justify-center">
        <GridLoader
          color="#38B6FF"
          loading={load}
          margin={4}
          size={10}
          speedMultiplier={1}
          width={5}
        />
      </div>
    </div>
  );
}
