/* eslint-disable linebreak-style */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable import/extensions */

import React from "react";

import { FooterPartsResources } from "json/landingPageData";

import BrandIcon from "./BrandIcon";

import Button from "../elements/Button";

export default function Footer() {
  const footerMessage = FooterPartsResources();
  return (
    <div className="bg-gray-50 border-t border-gray-200 pb-6">
      <div className="container flex-col mx-auto ">
        <div className="flex flex-col sm:flex-row mt-8 justify-center">
          <div className="w-1/3 flex-col ml-16 mr-8">
            <BrandIcon />
            <p className="w-full text-lg text-gray-400 font-light">
              {footerMessage.CONTACT_DESCRIPTION}
              <br />
              +90 506 440 69 10
              <br />
              {footerMessage.CONTACT_SUBDESCRIPTION}
            </p>
          </div>
          <div className="w-1/3 mt-0 ml-16 mr-0 sm:ml-0 sm:mr-5">
            <h1 className="text-lg text-theme-blue pt-4 pb-2">
              {footerMessage.CONTACT_OFFICE}
            </h1>
            <p className="text-lg text-gray-400 font-light">Ankara,Turkey</p>
            <p className="text-lg text-gray-400 font-light">
              aimazingapps@gmail.com
            </p>
          </div>
          <div className="w-1/2 ml-16 sm:ml-0 mt-0">
            <h1 className="text-lg text-theme-blue pt-4 pb-2">
              {footerMessage.CONTACT_SOCIAL}
            </h1>
            <Button
              href="https://www.instagram.com/aimazingapps/"
              type="link"
              target="_blank"
              className="flex text-lg text-gray-400 font-light hover:underline"
              isExternal
            >
              Instagram
            </Button>
            <Button
              href="https://www.linkedin.com/company/aimazingapps/"
              type="link"
              target="_blank"
              className="flex text-lg text-gray-400 font-light hover:underline"
              isExternal
            >
              LinkedIn
            </Button>
          </div>
        </div>
        <div className="flex-col text-center mt-7">
          <p className="text-lg text-gray-400 font-light">
            {footerMessage.COPYRIGHT}
          </p>
          <div className="flex-row">
            <p className="inline-block text-lg text-gray-400 font-light">
              Made with &#x2764; by &nbsp;
            </p>
            <Button
              target="_blank"
              className="text-lg text-theme-purple font-light"
              isExternal
            >
              AimazingApps
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
