/* eslint-disable linebreak-style */
/* eslint-disable import/extensions */
/* eslint-disable react/jsx-filename-extension */

import React from "react";
import kvkkData from "../zikirmatik_kvkk.json";

export default function Document(path) {
  const { title, sections } = getKvkkData(path);

  return (
    <div className="w-full  lg:w-1/2 xl:pl-12 sm:pr-2 mt-16 mb-16">
      <h1 className="text-3xl sm:text-3xl text-theme-blue font-bold leading-tight mb-5">
        {title}
      </h1>
      {sections.map((section) => (
        <div key={section.id}>
          <h2>{section.title}</h2>
          <p className="font-light text-xl text-gray-600 leading-relaxed mb-2">
            {section.content}
          </p>
        </div>
      ))}
    </div>
  );
}

function getKvkkData(path) {
  const zikirmatik = "ZikirmatikKVKK";
  switch (path) {
    case zikirmatik:
      return kvkkData.kvkk;
    default:
      return kvkkData.kvkk;
  }
}
